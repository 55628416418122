<template>
    <zw-select-group v-model="inputVal.unit"
                     :options="getUnits() | optionsVL"
                     name="unit"
                     disable-label
                     @change="savePosition('unit',inputVal)"
                     :readonly="readOnly"
                     size="sm"
    ></zw-select-group>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'UnitColumn',
    props: {
        value: [Object],
        savePosition: [Function],
        readOnly: [Boolean],
    },
    methods: {
        ...mapGetters('CommonData', ['getUnits']),
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>